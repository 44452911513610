.success{
  color:#1c6e37
}

.danger{
  color:#893232
}

.info{
  color:#1f5c99
}

.warning{
  color:rgba(131,107,52,.97)
}