.loader{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top:0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0008;
    overflow: hidden;
    z-index: 999999;
}