.react-datetime-picker__wrapper{
    border:none !important;
}

.heading{
    font-size: 18px;
    font-weight: bolder;
}
.sub-heading{
    font-size: 14px;
    font-weight: lighter;
}
.link{
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
}