// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

:root{
    --cui-primary: #0054f7;
    --cui-secondary: #3440EF;
    --cui-success: #28C497;
    --cui-info: #2398f3;
    --cui-light: #DDE5F0;
    --cui-dark: #243445;
    --cui-grey:#87949d;
    --cui-light1:#c5cdd7;
    --cui-header-bg:#F2F5FA;
    
}

.sidebar{
    --cui-sidebar-bg: #FBFBFB;
    --cui-sidebar-color: var(--cui-dark);
    --cui-sidebar-brand-color: var(--cui-dark);
    --cui-sidebar-nav-link-color:var(--cui-dark);
    --cui-sidebar-nav-link-hover-bg:var(--cui-primary);
    --cui-sidebar-nav-link-hover-color: #fff;
    --cui-sidebar-nav-link-active-color:#fff;
    --cui-sidebar-nav-link-active-bg:var(--cui-primary);
}

.sidebar-nav .nav-icon {
    --cui-sidebar-nav-link-icon-color:#87949d;
}

.sidebar-brand {
    --cui-sidebar-brand-color:var(--cui-dark);
    --cui-sidebar-brand-bg: #FBFBFB;
}

.sidebar-toggler {
    --cui-sidebar-toggler-bg: #FBFBFB;
    --cui-sidebar-toggler-hover-bg:var(--cui-primary);
    --cui-sidebar-toggler-hover-color:#fff;
}
.header {
    --cui-header-bg:#F2F5FA;
}
.bg-light {
    background-color: #F2F5FA !important;
}